import store from "@/store";

export const verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet = (idProjet) =>{    
  let ressourceList = store.state.utilisateur.utilisateur.ressources.map(function (ressource) {
    return ressource.idRessource
  });
  return store.getters["utilisateur/nomRole"] === "ADMIN" ||
    store.getters["utilisateur/nomRole"] === "ADMIN_ENT" ||
    ressourceList.includes(
    store.state.entreprise.projets[idProjet].idChefDeProjet) ||
    ressourceList.some(i => store.state.entreprise.projets[idProjet].suppleants.map((suppleant) => suppleant.idRessource).includes(i));
}

