var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('liste',{attrs:{"nom-page":_vm.nomPage,"label-bouton-ajout-element":"Créer un projet","key-name":"idProjet","default-sort-by":"nomProjet","items-table":_vm.listeProjetsFormateeFiltree,"loading":_vm.loading,"titres-table":_vm.headers,"mobile-breakpoint":750},on:{"ligne-selectionnee":_vm.visualiserLeProjetSurLaPageDAccueil,"ajout-element":function($event){return _vm.ouvrirFormulaireProjet(0)}},scopedSlots:_vm._u([{key:"filtre",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-checkbox',{staticClass:"mt-0 pt-0 mx-1",attrs:{"test-auto":_vm.nomPage + '_filtre_favori',"hide-details":"","on-icon":"mdi-star-face","off-icon":"mdi-star-face"},model:{value:(_vm.afficherUniquementLesFavoris),callback:function ($$v) {_vm.afficherUniquementLesFavoris=$$v},expression:"afficherUniquementLesFavoris"}})],1)]}}])},[(_vm.afficherUniquementLesFavoris)?_c('span',[_vm._v("Afficher tous les projets")]):_c('span',[_vm._v("Afficher uniquement les favoris")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-checkbox',{staticClass:"mt-0 pt-0 mx-1",attrs:{"test-auto":_vm.nomPage + '_filtre_projetCloture',"hide-details":"","on-icon":"mdi-archive","off-icon":"mdi-archive-outline"},model:{value:(_vm.afficherAussiLesProjetsClotures),callback:function ($$v) {_vm.afficherAussiLesProjetsClotures=$$v},expression:"afficherAussiLesProjetsClotures"}})],1)]}}])},[(_vm.afficherAussiLesProjetsClotures)?_c('span',[_vm._v("Masquer les projets clôturés")]):_c('span',[_vm._v("Afficher les projets clôturés")])])]},proxy:true},{key:"actions",fn:function(actionsSlotProps){return [_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"input-value":_vm.favoris.includes(actionsSlotProps.item.idProjet),"on-icon":"mdi-star","readonly":_vm.processingFavori,"disabled":_vm.processingFavori,"off-icon":"mdi-star-outline","color":"orange","hide-details":"","dense":""},on:{"change":function($event){return _vm.processFavori($event, actionsSlotProps.item.idProjet)}},nativeOn:{"click":function($event){$event.stopPropagation();}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
              _vm.verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet(
                actionsSlotProps.item.idProjet
              )
            )?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.ouvrirFormulaireProjet(actionsSlotProps.item.idProjet)}},nativeOn:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modifier le projet")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }