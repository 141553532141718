<template>
  <div>
    <liste
      :nom-page="nomPage"
      label-bouton-ajout-element="Créer un projet"
      key-name="idProjet"
      default-sort-by="nomProjet"
      :items-table="listeProjetsFormateeFiltree"
      :loading="loading"
      :titres-table="headers"
      :mobile-breakpoint="750"
      @ligne-selectionnee="visualiserLeProjetSurLaPageDAccueil"
      @ajout-element="ouvrirFormulaireProjet(0)"
    >
      <template #filtre>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-checkbox
                v-model="afficherUniquementLesFavoris"
                :test-auto="nomPage + '_filtre_favori'"
                hide-details
                class="mt-0 pt-0 mx-1"
                on-icon="mdi-star-face"
                off-icon="mdi-star-face"
              ></v-checkbox>
            </span>
          </template>
          <span v-if="afficherUniquementLesFavoris"
            >Afficher tous les projets</span
          >
          <span v-else>Afficher uniquement les favoris</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-checkbox
                v-model="afficherAussiLesProjetsClotures"
                :test-auto="nomPage + '_filtre_projetCloture'"
                hide-details
                class="mt-0 pt-0 mx-1"
                on-icon="mdi-archive"
                off-icon="mdi-archive-outline"
              ></v-checkbox>
            </span>
          </template>
          <span v-if="afficherAussiLesProjetsClotures"
            >Masquer les projets clôturés</span
          >
          <span v-else>Afficher les projets clôturés</span>
        </v-tooltip>
      </template>
      <template #actions="actionsSlotProps">
        <!-- TODO: have a REST endpoint to add favoris,
                      then we will be able to avoid concurrency issues.
                      In the meantime, favoris checkbox are disabled during processing -->
        <v-checkbox
          :input-value="favoris.includes(actionsSlotProps.item.idProjet)"
          on-icon="mdi-star"
          :readonly="processingFavori"
          :disabled="processingFavori"
          off-icon="mdi-star-outline"
          color="orange"
          hide-details
          class="pt-0 mt-0"
          dense
          @click.native.stop
          @change="processFavori($event, actionsSlotProps.item.idProjet)"
        ></v-checkbox>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet(
                  actionsSlotProps.item.idProjet
                )
              "
              small
              icon
              class="mx-2"
              v-bind="attrs"
              @click.native.stop
              @click="ouvrirFormulaireProjet(actionsSlotProps.item.idProjet)"
              v-on="on"
            >
              <!-- TODO: trigger generic modal on edition -->

              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Modifier le projet</span>
        </v-tooltip>
      </template>
    </liste>
  </div>
</template>
<script>
import Liste from "@/views/ListeGenerique";
import { mapActions, mapGetters, mapState } from "vuex";
import { EventHub } from "@/event-hub.js";
import { verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet } from "@/utils/conditions";

export default {
  components: {
    Liste,
  },
  data: () => ({
    nomPage: "projet",
    loading: true,
    processingFavori: false,
    afficherUniquementLesFavoris: false,
    afficherAussiLesProjetsClotures: false,
    verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet: verifierSiLUtilisateurALeDroitDouvrirLeFormulaireProjet,
  }),
  computed: {
    ...mapState("entreprise", ["utilisateurs", "entreprises", "projets"]),
    ...mapState("utilisateur", ["utilisateur"]),
    ...mapGetters("utilisateur", ["favoris", "nomRole"]),
    ...mapGetters("entreprise", ["getPrenomNom"]),
    headers: function() {
      let headers = [
        { text: "Nom", value: "nomProjet", align: "start" },
        { text: "N° Affaire", value: "numAffaire", width: "135px" },
        { text: "Début", value: "dateDebut", width: "107px" },
        { text: "Chef de projet", value: "chefDeProjet", width: "180px" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "100px",
        },
      ];
      if (this.utilisateur.ressources.length > 1 || this.nomRole === "ADMIN") {
        headers.splice(4, 0, {
          text: "Entreprise",
          value: "entreprise",
          width: "200px",
        });
      }
      if (this.afficherAussiLesProjetsClotures) {
        headers.splice(3, 0, {
          text: "Clôture",
          value: "dateCloture",
          width: "107px",
        });
      }
      return headers;
    },
    listeProjetsFormateeFiltree: function() {
      const listeProjetsFormatee = Object.values(this.projets).map(
        (projet) => ({
          ...projet,
          entreprise: this.entreprises[projet.idEntreprise].nomEntreprise,
          chefDeProjet: `${this.getPrenomNom(
            projet.idChefDeProjet,
            true,
            false
          )}`,
        })
      );

      let listeProjetsFormateeFiltree = listeProjetsFormatee;

      if (this.afficherUniquementLesFavoris) {
        listeProjetsFormateeFiltree = listeProjetsFormateeFiltree.filter(
          (projet) => this.favoris.includes(projet.idProjet)
        );
      }

      if (!this.afficherAussiLesProjetsClotures) {
        listeProjetsFormateeFiltree = listeProjetsFormateeFiltree.filter(
          (projet) => projet.actif
        );
      }

      return listeProjetsFormateeFiltree;
    },
  },
  watch: {
    //
  },
  created() {
    this.recupererProjets()
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("entreprise", ["recupererProjets", "recupererUtilisateurs"]),
    ...mapActions("utilisateur", ["modifierFavori", "setIdProjetSelectionne"]),
    ouvrirFormulaireProjet(idProjet) {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "projet",
        id: idProjet,
      });
    },
    visualiserLeProjetSurLaPageDAccueil(projetSelectionne) {
      if ("idProjet" in projetSelectionne) {
        this.setIdProjetSelectionne(projetSelectionne.idProjet);
        this.$router.push("/");
      }
    },
    processFavori(favoriEtatSouhaite, idProjet) {
      this.loading = true;
      this.processingFavori = true;

      this.modifierFavori({
        favoriEtatSouhaite,
        idProjet,
      }).finally(() => {
        this.processingFavori = false;
        this.loading = false;
      });
    },
    //
  },
};
</script>
<style scoped>
.toolbar-header {
  background-image: linear-gradient(to right top, #004087, #0071b1, #009da0);
  border-radius: 4px;
  text-transform: uppercase;
}

::v-deep
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: #d8e6ff !important;
  cursor: pointer;
}
</style>
